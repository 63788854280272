<template>
    <footer class="footer has-cards">
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-6">
                    <h3 class="text-primary font-weight-light mb-2">Contactanos!</h3>
                    <p>
                        Rigada Tecnología<br>
                        Ricardo Perez Alvarez<br>
                        Sur 67 #3118-201<br>
                        Iztacalco<br>
                        CDMX, Mexico<br>
                    </p>
                    <p>
                        +52 55 8435 4795 <br>
                        +52 55 1353 8864
                    </p>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        <a href="https://rigada.com.mx" target="_blank" rel="noopener">Rigada Tecnologia</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
